@import '/apps/wt/webtools/sparta_build_server/tmp/EE81542B/artifacts/components/utilities/global/sparta-style-utility/4.9.4/scss/_sparta-project.scss';
$moduleNameSpace: dynamic-name-space;





[data-sparta-container] .#{$moduleNameSpace} {
  margin-bottom: $spacing-20;
  margin-top: $spacing-20;

  @media #{$medium-up} {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  h2 {
    font-size: 24px;

    @media #{$medium-up} {
      font-size: 28px;
    }
    @media #{$large-up} {
      font-size: 34px;
    }
  }

  p {
    color: $brown-tertiary;
    font-size: 16px;
  }

  img {
    width: 64px;
    height: 70px;
  }
}
